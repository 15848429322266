import Vue, {ref, Ref} from 'vue'
import {defineStore} from "pinia"
import {CrudAccess, auth, AuthState} from "@hc/graphql"
import Session from "supertokens-web-js/recipe/session"
import {useConfigStore} from "./config"
import jwt from "jsonwebtoken"
import moment from 'moment'
import {vueContext} from '@hc/ui/vue'
import Uri from "urijs"
import ThirdParty from "supertokens-web-js/recipe/thirdparty"

import {Logger} from '@hc/base'
import {useCronStore} from "./cron";
import {AppBootParams} from "../bootstrap";

const logger = new Logger('AuthStore')

// create auth state
const authState: Ref<AuthState> = ref({
  token: null,
  user: null,
  access: new CrudAccess(null),
  loggedIn: false,
  payload: null,
  role: null,
})
auth.setAuthState(authState.value)

export interface AuthStorePayload {
  app: string
  instance: string
  recipe: string
  scope: string
  jwt: string
  supertokens_user_id: string
  user: Record<string, any>
  'https://hasura.io/jwt/claims': Record<string, string|Array<string>>
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    payload: {} as AuthStorePayload,
    isLoginRequired: false,
    isLoading: false,
  }),
  getters: {
    user: (state) => state.payload.user,
    recipe: (state) => state.payload.recipe,
    scope: (state) => state.payload.scope,
    app: (state) => state.payload.app,
    instance: (state) => state.payload.instance,
    supertokensUserId: (state) => state.payload.supertokens_user_id,
    hasuraClaims: (state) => state.payload['https://hasura.io/jwt/claims'],
    jwt: (state) => state.payload.jwt,
    access: (state) => new CrudAccess(state.payload.user),
    jwtPayload: (state) => state.payload.jwt ? jwt.decode(state.payload.jwt): null as jwt.JwtPayload,
    jwtExpiry: (state) => {
      if(!state?.payload?.jwt) return new Date()
      const decoded = jwt.decode(state.payload.jwt) as jwt.JwtPayload
      return new Date(decoded.exp * 1000)
    },
    jwtExpiryMinutes: (state) => {
      if(!state?.payload?.jwt) return 0
      const decoded = jwt.decode(state.payload.jwt) as jwt.JwtPayload
      return moment(new Date(decoded.exp * 1000)).diff(moment(), 'minutes')
    },
  },
  actions: {
    async boot(params: AppBootParams){
      const config = useConfigStore()
      const cron = useCronStore()
      logger.log("Activating session")
      const location = new Uri(window.location)

      if(location.hasQuery('token')) {
        logger.log("Jwt present in query param")
        const token = location.query(true)['token']
        const payload = jwt.decode(token) as object
        this.setSession({
          ...payload,
          jwt: token,
        })
      }
      else if(await Session.doesSessionExist()) {
        logger.log("Session exists and is valid")
        try {
          const payload = await Session.getAccessTokenPayloadSecurely()
          logger.log("Payload", payload)

          // check if token is for the current app
          if(payload.app !== config.app){
            logger.log('Session from different app detected, goto login.')
            await Session.signOut()
            window.location.href = config.uiPath+'?login'

           let redirectPath = location.path().substring(params.uiPath.length)
            if(!redirectPath.startsWith('/')) redirectPath = '/'+redirectPath
            window.localStorage[`${params.appName}.${params.scope}.afterLogin`] = redirectPath
            return
          }

          if(params.jwtAllowed && !params.jwtAllowed(payload)){
            logger.log('Session not accepted in this scope, goto login.')
            await Session.signOut()
            window.location.href = config.uiPath+'?login'
            return
          }

          this.setSession(payload)

        } catch(e) {
          logger.exception(e, "Session expired, goto login.")
          this.login()
          return
        }
      }
      if(config.isProd){
        cron.register({
          name: 'AuthStore.refreshSession',
          timeout: 300000,
          handler: this.refreshSession
        })
      }
      if (config.isDev) {
        localStorage.setItem(`${config.app}.${config.scope}.token`, this.jwt)
      }
    },
    setSession(payload){
      const config = useConfigStore()
      // configure local auth state
      this.payload = payload

      // set graphql jwt token for hasura
      config.api.config.graphqlAuthToken = this.jwt

      // set AppContext auth information
      auth.state.user = payload.user
      auth.state.payload = payload
      auth.state.token = this.jwt
      auth.state.role = this.user.role
      auth.state.access = this.access
      auth.state.loggedIn = true

      // set auth to store if vuex is used
      if(vueContext.vuex){
        vueContext.vuex.dispatch('user/loginUser', this.jwt)
      }

      logger.log("Session updated, tokens expires on=%s in=%s minutes", this.jwtExpiry, this.jwtExpiryMinutes)
    },
    async refreshSession(){
      //logger.log("Refresh session")
      const refreshed = await Session.attemptRefreshingSession()
      if(!refreshed){
        logger.error('Cannot refresh session, going to login.')
        this.login()
      } else {
        const payload = await Session.getAccessTokenPayloadSecurely()
        this.setSession(payload)
        //logger.log("Session refreshed")
      }
    },
    async login(){
      logger.log("Logging in")
      const config = useConfigStore()
      window.location.href = config.uiPath
    },
    async logout(){
      logger.log("Logging out")
      await Session.signOut()
      const config = useConfigStore()
      window.location.href = config.uiPath
    },
    async requireLogin() {
      this.isLoginRequired = true
    },
    async initiateThirdPartyLogin(){
      const config = useConfigStore()
      const baseUrl = config.get('services.ui')
      const callbackUrl = '/auth/callback/microsoft'
      const authUrl = await ThirdParty.getAuthorisationURLWithQueryParamsAndSetState({
        providerId: "microsoft",
        authorisationURL: config.app === 'eguide' ? `${baseUrl}/admin${callbackUrl}`: `${baseUrl}${callbackUrl}`,
      })
      window.location.href = authUrl
    },
    singinError() {
      // NOTE: thirdparty might not have been initialized
      try {
        return ThirdParty.getAuthErrorFromURL()
      } catch(e){
        return null
      }

    }
  },
})